import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { Accordion, } from 'react-bootstrap';
	
const defaultAccordion = [
	{
	  title: '1. What is the GUESSS India 2023 Report?',
	  text:
		'The GUESSS India 2023 Report is the inaugural edition of a comprehensive survey on student entrepreneurship across India, conducted as part of the Global University Entrepreneurial Spirit Students’ Survey (GUESSS) initiative. It provides insights into the entrepreneurial intentions, activities, and perceptions of Indian students, benchmarking them against global peers.',
	},
	{
	  title: '2. What is GUESSS Global?',
	  text:
		'GUESSS (Global University Entrepreneurial Spirit Students Survey) is a biennial global research project initiated by the University of St. Gallen, Switzerland, in 2003. It aims to understand entrepreneurial intent, behavior, and influencing factors among students worldwide. In 2023, 57 countries participated in the survey, collecting data from more than 300,000 students.',
	},
	{
	  title: '3. How many students participated in the GUESSS India 2023 Survey?',
	  text:
		'The GUESSS India 2023 Survey received responses from 13,896 students across 1298 higher education institutions in India, making it one of the most comprehensive studies on student entrepreneurship in the country.',
	},
	{
	  title: '4. How was the GUESSS India 2023 Survey conducted?',
	  text:
		'The GUESSS India 2023 Survey was conducted using an online questionnaire distributed to students across 156 higher education institutions in India. The survey covered a wide range of questions aimed at understanding students’ career intentions, entrepreneurial activities, and perceptions of their university’s entrepreneurship climate.',
	},
	{
	  title: '5. What types of institutions were included in the survey?',
	  text:
		'The survey primarily targeted the Top 100 Institutions from the NIRF 2023 Innovation Category and 88 Centrally Funded Technical Institutions (CFTIs). Additionally, several other higher educational institutions were included in the survey. Overall, students enrolled in 1298 Indian institutions provided their responses for the survey. The coverage includes universities, technical institutes, colleges, and other higher education establishments, ensuring a diverse representation of students from various academic backgrounds.',
	},
	{
		title: '6. What methodology was used for data collection?',
		text:
		  'The survey utilized an online questionnaire distributed via institutional networks, social media channels, and strategic partners who had direct communication with Indian students. A network of on campus Campus-Ambassadors was leveraged by the survey team. The questionnaire consisted of a mix of multiple-choice questions, and Likert-scale rating questions to capture the data.',
	  },
      {
		title: '7. How are career choices and entrepreneurial intentions measured in the survey?',
		text:
		  'Students were asked about their short-term and long-term career aspirations (immediately after graduation and five years later), as well as their intent to start a business at different stages of their academic and professional lives. Additionally, entrepreneurial intentions were measured through a series of questions using a 7-point Likert scale. ',
	  },
	  {
		title: '8. How is the university entrepreneurship climate assessed?',
		text:
		  'The university entrepreneurship climate is assessed using a 3-item scale that evaluates students’ perceptions of the entrepreneurial environment at their institutions. The scale measures factors such as the encouragement provided by universities, the support systems available for developing business ideas, and the overall culture fostering entrepreneurship.',
	  },
	  {
		title: '9. How is the distinction between nascent and active entrepreneurs made in the survey?',
		text:
		  'Students were classified as nascent entrepreneurs if they were in the process of starting a venture but had not yet generated consistent revenue for more than three months. Active entrepreneurs are those whose ventures have moved beyond the nascent stage and have been operational long enough to generate revenue and/or pay wages for at least three months.',
	  },
	  {
		title: '10. How does the GUESSS India 2023 Report ensure representative data?',
		text:
		  'The survey’s sample includes students from a diverse range of academic institutions, regions, and fields of study, ensuring that the data reflects the variety of experiences within India’s student population. The inclusion of both undergraduate and postgraduate students, across different types of institutions, helps provide a broad and representative dataset.',
	  },
		
		
]	
const AccordionBlog = () => {
	const [activeDefault, setActiveDefault] = useState(0)
	return(
		<Accordion className="accordion dlab-accordion faq-1 box-sort-in m-b30"  defaultActiveKey="-1">
			{defaultAccordion.map((d, i) => (
			   <div className="panel">
					<div className="acod-head">
						<h6 className="acod-title">
							<Accordion.Toggle as={Link} variant="link"
								className={` ${ activeDefault === i ? '' : 'collapsed'}`}
								onClick={() =>
									setActiveDefault(activeDefault === i ? -1 : i)
								} eventKey={`${i}`}>	
								 {d.title}		
							</Accordion.Toggle>
						</h6>	
					</div>
						
				<Accordion.Collapse eventKey={`${i}`} className="acod-body">
				  <div className="acod-content">
					{d.text}
					</div>
				</Accordion.Collapse>
			  </div>
			))}
		</Accordion>	
	)
}
export {defaultAccordion};
export default AccordionBlog;