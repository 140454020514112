import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Counter from './Counter';

import bg1 from './../../images/background/bg1.jpg';

const iconBlog =[
	{icon: <i className="flaticon-pen" />,desc:'To assess the aspirations and motivations of Indian students towards entrepreneurship.',	   title: 'Student Entrepreneurial Intention',},
	{icon: <i className="flaticon-diamond" />, desc:'To analyse the evolving career choice intention of Indian studnets towards entrepreneurship.',title: 'Students Career Choice Intention',},
	{icon: <i className="flaticon-devices" />, desc:'Evaluating students perceptions of the entrepreneurship culture and support at thier university.',title: 'University Entrepreneurship Climate',},
	
];

class SectionCounter extends Component{
	render(){
		return(
			<Fragment>
				<div className="section-full content-inner-1 overlay-primary about-service bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" ,
  height: window.innerWidth <= 1000 ? (window.innerWidth <= 900 ? '1000px' : '760px') : '600px' }}>
					<div className="container">
						<div className="section-head text-white text-center">
							<h2 className="box-title m-tb0 max-w800 m-auto">Why GUESSS India? <span className="bg-primary"></span></h2>
							<p style={{textAlign:"justify"}}>The primary goal of GUESSS India is to explore and understand the entrepreneurial intentions, attitudes, and behaviours of Indian students. This survey is conducted across the Indian higher educational institutions, encompassing a diverse range of students from various academic disciplines and educational levels.</p>
						</div>
					</div>
					<div className="container">
						<div className="row text-white">
							{iconBlog.map((item, index)=> (
								<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
									<div className="icon-bx-wraper bx-style-1 p-a30 center">
										{/* <div className="icon-lg text-white m-b20"> <Link to={"#"} className="icon-cell text-white">{item.icon}</Link> </div> */}
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">{item.title}</h5>
											<p>{item.desc}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					{/* <div className="choses-info text-white">
						<Counter />
					</div> */}
				</div>	
			</Fragment>
		)
	}
}
export default SectionCounter;