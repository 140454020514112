import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

import Slider5 from './../../images/main-slider/slide5.webp';
import Slider4 from './../../images/main-slider/slide5.webp';
import Slider2 from './../../images/main-slider/slide5.webp';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next flaticon-right-arrow"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{zIndex:1 }}/>
	</div>
  );
}

class Index7Slider extends Component{
	
	render(){
		var settings = {		
			arrows: true,
			dots: false,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
					
				  }
				}
			]
        };
		return(
			
			<Slider className="owl-slider owl-carousel owl-theme" {...settings}>
						<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider2} className="w-100" alt=""/></div>
					<div className="slide-content overlay-black-dark">
						<div className="slide-content-box container">
							<div className="max-w800 text-white justify-content-center">
								<h2 className="dz-title"><span style={{fontSize: '0.8em'}}>How many students are {''} <span style={{color:"#6CC000"}}>Enterprising</span> ?</span><br/></h2>
								<p>2023 Survey Report | 1st Edition</p>
								<div className="slide-btns"> 
									<Link to={"/coming-soon-1"} className="site-button  m-r10 radius-xl primary button-lg">Download</Link>
									<Link to={"/about-1"} className="site-button  radius-xl white  button-lg">Read More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider5} className="w-100" alt=""/></div>
					<div className="slide-content overlay-black-dark">
						<div className="slide-content-box container">
							<div className="max-w600 text-white ">
								<h2 className="dz-title"><span style={{fontSize: '0.9em'}}>Who wants to be an <span style={{color:"#6CC000"}}>Entrepreneur</span> ?</span><br/></h2>
								<p>2023 Survey Report | 1st Edition</p>
								<div className="slide-btns"> 
									<Link to={"/coming-soon-1"} className="site-button  m-r10 radius-xl primary button-lg">Download</Link>
									<Link to={"/about-1"} className="site-button  radius-xl white  button-lg">Read More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider2} className="w-100" alt=""/></div>
					<div className="slide-content overlay-black-dark">
						<div className="slide-content-box container">
							<div className="max-w800 text-white justify-content-center">
								<h2 className="dz-title"><span style={{fontSize: '0.8em'}}>What is driving India's student {''} <span style={{color:"#6CC000"}}> Startup </span>{''}surge?</span><br/></h2>
								<p>2023 Survey Report | 1st Edition</p>
								<div className="slide-btns"> 
									<Link to={"/coming-soon-1"} className="site-button  m-r10 radius-xl primary button-lg">Download</Link>
									<Link to={"/about-1"} className="site-button  radius-xl white  button-lg">Read More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider4} className="w-100" alt=""/></div>
					<div className="slide-content overlay-black-dark">
						<div className="slide-content-box container">
							<div className="max-w900 text-white justify-content-center">
								<h2 className="dz-title"><span style={{fontSize: '0.9em'}}>Do universities nuture an{''}<span style={{color:"#6CC000"}}> Entrepreneurial Climate</span>{''}?</span><br/></h2>
								<p>2023 Survey Report | 1st Edition</p>
								<div className="slide-btns"> 
									<Link to={"/coming-soon-1"} className="site-button  m-r10 radius-xl primary button-lg">Download</Link>
									<Link to={"/about-1"} className="site-button  radius-xl white  button-lg">Read More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
		
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider2} className="w-100" alt=""/></div>
					<div className="slide-content overlay-black-dark">
						<div className="slide-content-box container">
							<div className="max-w800 text-white justify-content-center">
								<h2 className="dz-title"><span style={{fontSize: '0.8em'}}>Do students have an {''} <span style={{color:"#6CC000"}}> Incubation Access</span>?</span><br/></h2>
								<p>2023 Survey Report | 1st Edition</p>
								<div className="slide-btns"> 
									<Link to={"/coming-soon-1"} className="site-button  m-r10 radius-xl primary button-lg">Download</Link>
									<Link to={"/about-1"} className="site-button  radius-xl white  button-lg">Read More</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
				
				
			</Slider>	
		)
		
	}
	
}

export default Index7Slider;