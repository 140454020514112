import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

//Images
import pic1 from './../../images/blog/grid/pic1.jpg';
import pic2 from './../../images/blog/grid/pic2.webp';
import pic3 from './../../images/blog/grid/pic3.webp';
import pic4 from './../../images/blog/grid/pic4.jpg';


const contentBlog =[
	{images: pic1, title: 'Why GUESSS India ? Igniting the Entrepreneurial Spirit of India students .', link:"./blog-details"}, 
	{images: pic2, title: 'India’s Students and the Entrepreneurial Spirit: Key Takeaways from the GUESSS India 2023 Report.', link:"./blog-details-2"}, 
	{images: pic3, title: 'University Entrepreneurship Climate: Nurturing Innovation Among Indian Students.', link:"./blog-details-3"}, 
	{images: pic1, title: 'Why GUESSS India ? Igniting the Entrepreneurial Spirit of India students .', link:"./blog-details"}, 
	{images: pic2, title: 'India’s Students and the Entrepreneurial Spirit: Key Takeaways from the GUESSS India 2023 Report.', link:"./blog-details-2"}, 
	{images: pic3, title: 'University Entrepreneurship Climate: Nurturing Innovation Among Indian Students.' , link:"./blog-details-3"} , 

]


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next flaticon-right-arrow"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
		<div className="owl-nav">
			<div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{zIndex:1}}/>
		</div>
    );
} 

class LatestBlogSlider extends Component{
	render(){
		var settings = {		
			arrows: true,
            slidesToShow: 4,		
            infinite: true,
			dots: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow:1,
				  }
				}
			]
        };
		return(
			<>
				<Slider className="dots-style-center img-carousel owl-carousel owl-btn-center-lr owl-btn-3 " {...settings}>
					{contentBlog.map((item,index)=>(
						<div className="item p-3" key={index}>
							<div className="blog-post blog-grid blog-rounded blog-effect1">
								<div className="dlab-post-media dlab-img-effect "> <Link to={item.link}><img src={item.images} style={{height:'200px'}} alt="" /></Link> </div>
								<div className="dlab-info p-a20 border-1">
									<div className="dlab-post-title ">
										<h5 className="post-title font-weight-500"><Link to={item.libk}>{item.title}</Link></h5>
									</div>
									<div className="dlab-post-meta ">
										<ul>
											<li className="post-date"> <i className="fa fa-comments"></i><strong>10 Oct</strong> <span> 2024</span> </li>
											<li className="post-author"><i className="fa fa-user"></i>By <Link to={item.link}>Javeed Dar</Link> </li>
										</ul>
									</div>
								
								   <div className="dlab-post-readmore"> 
										<Link to={item.link} title="READ MORE" rel="bookmark" className="site-button-link black outline">READ MORE 
											<i className="ti-arrow-right"></i>
										</Link> 
									</div>
								</div>
							</div>
						</div>
					))}					
				</Slider>
			</>
		)
	}
	
}

export default LatestBlogSlider;