import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import bnr from './../../../images/background/CS3.webp';
import { db } from '../../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import pdf from './../../../images/Summary.pdf';
import pdf2 from './../../../images/Report.pdf';
import img1 from './../../../images/slider/1.png';
import img2 from './../../../images/slider/2.png';
import img3 from './../../../images/slider/3.png';
import img4 from './../../../images/slider/4.png';
import img5 from './../../../images/slider/5.png';
import img6 from './../../../images/slider/6.png';
import img7 from './../../../images/slider/7.png';
import img8 from './../../../images/slider/8.png';
import img9 from './../../../images/slider/9.png';
import img10 from './../../../images/slider/10.png';
import img11 from './../../../images/slider/11.png';
import img12 from './../../../images/slider/12.png';
import img13 from './../../../images/slider/13.png';
import Service from './../Service/Service';

const ComingSoon1 = () => {
    const [showPopup1, setShowPopup1] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [email1, setEmail1] = useState('');
    const [name1, setName1] = useState('');
    const [phone1, setPhone1] = useState('');
    const [email2, setEmail2] = useState('');
    const [name2, setName2] = useState('');
    const [phone2, setPhone2] = useState('');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showCurtain, setShowCurtain] = useState(true); 

    const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13];

  

    const handleSubscribeClick1 = (e) => {
        e.preventDefault();
        setShowPopup1(true);
    };

    const handleSubscribeClick2 = (e) => {
        e.preventDefault();
        setShowPopup2(true);
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        const userData = { email: email2, name: name2, phone: phone2 };
        console.log('Submitting user data for form 1:', userData);
    
        if (!email2 || !name2 || !phone2) {
            console.error('Please fill all the fields');
            return;
        }
    
        try {
            await addDoc(collection(db, 'subscribers'), userData);
            console.log('User data submitted successfully!');
            setEmail2('');
            setName2('');
            setPhone2('');
    
            const link = document.createElement('a');
            link.href = pdf;
            link.download = 'Summary.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setShowPopup1(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        const userData = { email: email1, name: name1, phone: phone1 };
        console.log('Submitting user data for form 2:', userData);
    
        if (!email1 || !name1 || !phone1) {
            console.error('Please fill all the fields');
            return;
        }
    
        try {
            await addDoc(collection(db, 'subscribers'), userData);
            console.log('User data submitted successfully!');
            setEmail1('');
            setName1('');
            setPhone1('');
    
            const link = document.createElement('a');
            link.href = pdf2;
            link.download = 'Report.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setShowPopup2(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    let interval = useRef();

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
    };

    useEffect(() => {
        const slideInterval = setInterval(nextSlide, 4000);
        return () => clearInterval(slideInterval);
    }, [currentSlide, images.length]);

    return (
        <Fragment>
            
            <div className={`content-block ${showCurtain ? 'curtain-active' : ''}`}>
                <div className="section-full">
                    <div className="container-fluid">
                        <div className="row dzseth">
                            <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                <div style={{ height: 'auto' }}>
                                    <img src={bnr} alt="" className="img-cover" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                <div className="p-a30 dis-tbl-cell">
                                    <div className="max-w500 m-auto p-tb30">
                                        <div className="row">
                                            <div className="countdown-box" style={{ display: 'flex', flexDirection: 'column', gap: '40px', alignItems: 'flex-start' }}>
                                                <div className="button-home">
                                                    <Link to={"./"} className="btn" style={{ backgroundColor: '#6cc000', padding: '10px 20px', color: '#fff', borderRadius: '5px' }}>
                                                        <i className="fa fa-home"></i> Home
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="coming-head" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <h2 style={{ fontSize: '2em', marginLeft: '40px', marginTop: '30px', color: 'white' }}>
                                                    <strong>GUESSS 2023 REPORT</strong>
                                                </h2>
                                                <div className="subscribe-form" style={{ marginLeft: '40px', marginTop: '0px', marginBottom: '50px' }}>
                                                    <form className="dzSubscribe" onSubmit={handleSubscribeClick1}>
                                                        <div className="input-group">
                                                            <input
                                                                name="dzEmail"
                                                                required
                                                                className="form-control"
                                                                placeholder="Your Email Id"
                                                                type="email"
                                                                value={email1}
                                                                onChange={(e) => setEmail1(e.target.value)}
                                                                style={{ padding: '10px', borderRadius: '5px', marginRight: '10px' }}
                                                            />
                                                            <button type="submit" style={{ backgroundColor: '#6cc000', padding: '10px 20px', color: '#fff', borderRadius: '40px' }}>Download</button>
                                                        </div>
                                                    </form>

                                                    {showPopup1 && (
                                                        <div className="popup">
                                                            <div className="popup-content">
                                                                <h2 style={{ fontSize: '24px' }}>Enter your details</h2>
                                                                <form onSubmit={handleSubmit2}>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Your Name"
                                                                        value={name1}
                                                                        onChange={(e) => setName1(e.target.value)}
                                                                        required
                                                                    />
                                                                    <input
                                                                        type="tel"
                                                                        placeholder="Your Phone Number"
                                                                        value={phone1}
                                                                        onChange={(e) => setPhone1(e.target.value)}
                                                                        required
                                                                    />
                                                                    <button type="submit">Submit</button>
                                                                    <button type="button" onClick={() => setShowPopup1(false)}>Cancel</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <h2 style={{ fontSize: '2em', marginLeft: '40px', marginTop: '30px', color: 'white' }}>
                                                    <strong>GUESSS 2023 SUMMARY</strong>
                                                </h2>
                                                <div className="subscribe-form" style={{ marginLeft: '40px', marginTop: '0px', marginBottom: '50px' }}>
                                                    <form className="dzSubscribe" onSubmit={handleSubscribeClick2}>
                                                        <div className="input-group">
                                                            <input
                                                                name="dzEmail"
                                                                required
                                                                className="form-control"
                                                                placeholder="Your Email Id"
                                                                type="email"
                                                                value={email2}
                                                                onChange={(e) => setEmail2(e.target.value)}
                                                                style={{ padding: '10px', borderRadius: '5px', marginRight: '10px' }}
                                                            />
                                                            <button type="submit" style={{ backgroundColor: '#6cc000', padding: '10px 20px', color: '#fff', borderRadius: '40px' }}>Download</button>
                                                        </div>
                                                    </form>

                                                    {showPopup2 && (
                                                        <div className="popup">
                                                            <div className="popup-content">
                                                                <h2 style={{ fontSize: '24px' }}>Enter your details</h2>
                                                                <form onSubmit={handleSubmit1}>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Your Name"
                                                                        value={name2}
                                                                        onChange={(e) => setName2(e.target.value)}
                                                                        required
                                                                    />
                                                                    <input
                                                                        type="tel"
                                                                        placeholder="Your Phone Number"
                                                                        value={phone2}
                                                                        onChange={(e) => setPhone2(e.target.value)}
                                                                        required
                                                                    />
                                                                    <button type="submit">Submit</button>
                                                                    <button type="button" onClick={() => setShowPopup2(false)}>Cancel</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                        </div>
                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                <div className="slider-container" style={{ position: "relative", width: "100%", height: "auto", overflow: "hidden", marginTop: "0px" }}>
                    <img
                        src={images[currentSlide]}
                        alt={`Slide ${currentSlide}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />

                    <button onClick={prevSlide} className="prev" >
                        &#10094;
                    </button>
                    <button onClick={nextSlide} className="next">
                        &#10095;
                    </button>
                </div>
               
                                <div className="row m-b50">
                                    <div className="col-md-12 m-b20">
                                        <div className="p-a30 bg-gray">
                                            <h2>Expected Research Output </h2><p  style={{textAlign:"justify"}}>The culmination of our research journey is a series of academic and practitioner-oriented publications. These publications are more than ink on paper; they're catalysts for dialogue, strategies, and transformation. Immerse yourself in the depth of knowledge stemming from GUESSS India's research. Each publication becomes a stepping stone in the journey toward a more vibrant, innovative, and supportive environment for student entrepreneurship. </p>
                                            <div className="section-full  bg-gray">
                          
                            
                          <div className="row  bg-gray">
                            
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" style={{height:"250px" , overflow:"scroll"}}>
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"16px"}}>Maiden India Dataset</h5>
                                              <p style={{textAlign:"justify"}}>Data collection forms the bedrock of GUESSS India's research journey. By gathering information from students across various universities in India, GUESSS India will create the maiden dataset to unravel current trends and future transformations in student entrepreneurial spirit.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" style={{height:"250px" , overflow:"scroll"}}>
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"16px"}}>Country Report</h5>
                                              <p style={{textAlign:"justify"}}>Anticipate the unveiling of a compelling narrative within the GUESSS India Country Report. This meticulously curated document will be a testament to the collective effort of Indian universities, shedding light on the intricate tapestry of student entrepreneurship. Beyond data, this report will tell stories of innovation, resilience, and vision. Its significance extends beyond academia, offering valuable insights for policymakers, educational institutions, and the entrepreneurial ecosystem.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" style={{height:"250px" , overflow:"scroll"}}>
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"16px"}}>White Papers and Academic Articles</h5>
                                              <p style={{textAlign:"justify"}}>Data is the foundation, but knowledge is the catalyst for change. GUESSS India stands as a platform for the creation of white papers and academic articles that transcend mere numbers. By analyzing data through various lenses, we're fostering thought leadership that resonates with scholars, practitioners, and policymakers alike. Prepare to immerse yourself in a pool of insights that challenge assumptions and drive action.</p>
                                          </div>
                                      </div>
                                  </div>

                            
                          </div>
                     
                  </div>
											
								
                                        </div>
                                        
                                    </div>
                                    
                                   
                                </div>
                                <div className="row m-b50">
                                    <div className="col-md-12 m-b20">
                                        <div className="p-a30 bg-gray">
                                            <h2>Past Research </h2><p  style={{textAlign:"justify"}}>GUESSS survey has resulted in large global datasets in the past 15 years which have immensely contributed to the global student entrepreneurship literature. Following are several thematic areas in which GUESSS dataset has been used for conducting research.</p>
                                           
                                            <p>Check <a href='https://www.guesssurveyorg/publications/publications/academic-journals.html' style={{color:"green" , fontWeight:"bold"}}>GUESSS Academic Publications</a></p>
                                            <div className="section-full  bg-gray">
                          
                            
                          <div className="row  bg-gray">
                            
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Entrepreneurial Decision Making</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Phenomenon of Student Entrepreneurship</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Motivations to start a venture</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Career Choice Intentions</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Personality Traits of Student Entrepreneurs</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Social Identity formation in Student Entrepreneurs</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Gender Prespective in Student Entrepreneurship</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>University Entrepreneurship Climate</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Impact of Entrepreneurship Education</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Entrepreneurial Intentions of Students</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Entrepreneurial Activities of Student Entrepreneurs</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content" >
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Succession Intentions of second generation entrepreneurs</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6 m-b30 "  >
                                      <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center ">
                                          
                                          <div className="icon-content">
                                              <h5 className="dlab-tilte text-uppercase" style={{fontSize:"22px"}}>Impact of Family Business Background</h5>
                                             
                                          </div>
                                      </div>
                                  </div>
                                

                            
                          </div>
                     
                  </div>
											
								
                                        </div>
                                        
                                    </div>
                                    
                                   
                                </div>
            </div>
            <style jsx>{`
                .popup {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }
                .popup-content {
                    background: white;
                    padding: 20px;
                    border-radius: 5px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                    width: 300px;
                    text-align: center;
                }
                .popup-content input {
                    width: calc(100% - 20px);
                    margin-bottom: 10px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }
                .popup-content button {
                    padding: 10px 15px;
                    margin: 5px;
                    background-color: #6cc000;
                    border: none;
                    border-radius: 20px;
                    color: white;
                    cursor: pointer;
                }
                .popup-content button:hover {
                    background-color: #5cb85c;
                }
                

                .slider-container {
                    position: relative;
                }
                .prev, .next {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    padding: 10px;
                    background-color: rgba(0, 0, 0, 0.5);
                    color: white;
                    border: none;
                    font-size: 24px;
                    transform: translateY(-50%);
                }
                .prev {
                    left: 10px;
                }
                .next {
                    right: 10px;
                }
                @media (max-width: 768px) {
                    .coming-soon {
                        background-image: none;
                        background-color: #333; /* Dark grey for mobile view */
                    }
                    .countdown-box, .coming-head {
                        align-items: center;
                    }
                    .subscribe-form {
                        text-align: center;
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default ComingSoon1;
