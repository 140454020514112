import React, { Component } from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import HomeOwlSlider from './../Element/HomeOwlSlider';
import HomeOwlSlider2 from './../Element/HomeOwlSlider2';
import ImgCarouselContent from './../Element/ImgCarouselContent';
import HomeTab from './../Element/HomeTab';
import Counter from './../Element/Counter';
import PortfolioCarousel from './../Element/PortfolioCarousel';
import TestimonialCarousel from './../Element/TestimonialCarousel';
import FormStyle from './../Element/FormStyle';

// Images
import pic4 from './../../images/puran.jpg';
import bgimg from './../../images/background/bg1.jpg';
import bgimg2 from './../../images/background/bg-map.jpg';
import bgimg3 from './../../images/background/bg1.jpg';

class Homepage extends Component {
    componentDidMount() {
        // Additional logic can go here
    }

    render() {
        return (
            <div className="page-wraper">
                <Header />
                <div className="page-content bg-dark">
                    {/* Slider Banner */}
                    {/* <div className="owl-slider-banner main-slider">
                        <HomeOwlSlider />
                        <div className="service-box-slide">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ImgCarouselContent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* About Us Section */}
                    <div className="content-block" >
                      
                      <div className="abuot-box row" style={{ display:"flex", flexDirection:"row" , flexWrap:"wrap", padding: '20px', paddingTop:"140px" }} >
                        <div className="col-lg-4">
                          <div className="about-img">
                            <img src={pic4} data-tilt alt="" />
                          </div>
                          <h2 className="box-title m-tb10" style={{ lineHeight: '30px' , color:"white" }}>
                            Dr. Puran Singh
                            <span className="bg-primary"></span>
                          </h2>
                          <h4 style={{  color:"white" }} >Country Delegate, GUESSS India</h4>
                          <p style={{ lineHeight: '30px' , color:"white" }}>Associate Professor [Finance & Entrepreneurship] School of Management, Indian Institute of Technology Mandi <br/>Ex-Director Incubation & Founding Faculty Incharge, IIT Mandi Catalyst</p>
                        
                        </div>
                        <div className="col-lg-8">
                          <p style={{ fontSize: '20px', lineHeight: '28px', textAlign: 'justify' , width: window.innerWidth <= 768 ? '100%' : '98%' , color:"white" , fontWeight:"530" }}>
                          Dr. Puran Singh is the Country Delegate for GUESSS India. He currently serves as an Associate Professor at the School of Management in IIT Mandi, Himachal Pradesh. His research in entrepreneurship focuses on new venture creation dynamics, student entrepreneurship, and incubation ecosystem. He teaches courses on finance and entrepreneurship at undergraduate, postgraduate, and doctoral levels. Dr. Puran is the founding faculty in charge and Ex-Director Incubation of IIT Mandi Catalyst, the first technology business incubator in Himachal Pradesh. He was the head of IIT Mandi Catalyst during 2016-2023. He has played a pivotal role in enabling the startup ecosystem in the state by powering the venture journeys of close to 400 startups. He has been working in tandem with the government agencies at the state and central government and the larger startup ecosystem in the country. He served on the Governing Board of the Indian STEPS and Incubators’ Association (ISBA) during 2022-23. He served as a member of the CII Northern Region Committee on Startup during 2023-25 and has been a Co-convener on the CII HP Panel on Education & Startups during 2022-23. He has been invited to speak at various conferences, events, and platforms. He has facilitated several capacity-building programs on entrepreneurship for startups, students, and faculty.
                          </p>
                        </div>
                      </div>
                    </div>
                  
                </div>
                <Footer />
            </div>
        );
    }
}

export default Homepage;
