import React, { Component } from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import HomeOwlSlider from './../Element/HomeOwlSlider';
import HomeOwlSlider2 from './../Element/HomeOwlSlider2';
import ImgCarouselContent from './../Element/ImgCarouselContent';
import HomeTab from './../Element/HomeTab';
import Counter from './../Element/Counter';
import PortfolioCarousel from './../Element/PortfolioCarousel';
import TestimonialCarousel from './../Element/TestimonialCarousel';
import FormStyle from './../Element/FormStyle';

// Images
import pic4 from './../../images/portfolio/image_4.png';
import bgimg from './../../images/background/bg1.jpg';
import bgimg2 from './../../images/background/bg-map.jpg';
import bgimg3 from './../../images/background/bg1.jpg';

class Homepage2 extends Component {
    componentDidMount() {
        // Additional logic can go here
    }

    render() {
        return (
            <div className="page-wraper">
                <Header />
                <div className="page-content bg-dark">
                    {/* Slider Banner */}
                    {/* <div className="owl-slider-banner main-slider">
                        <HomeOwlSlider />
                        <div className="service-box-slide">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ImgCarouselContent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* About Us Section */}
                    <div className="content-block">
                        <div className="section-full bg-dark content-inner-1 about-us">
                            <div className="container" style={{ padding: '0', margin: window.innerWidth <= 768 ? '80px 0px' : '80px 30px'}}>
                                <div className="row" style={{margin:"0px auto"}}>
                                    <div
                                        className="col-lg-5 col-md-4 about-img"
                                        style={{
                                            transform: window.innerWidth <= 768 ? 'none' : 'translateY(10px)',
                                            transform: window.innerWidth <= 768 ? 'none' : 'translateX(60px)',
                                            transition: 'transform 0.1s ease',
                                        }}
                                    >
                                        <img src={pic4} style={{height:"300px"}}data-tilt alt="" />
                                    </div>
                                    <div
                                        className="col-lg-7 col-md-6"
                                        style={{
                                            transform: window.innerWidth <= 768 ? 'none' : 'translateX(-130px)',
                                            transition: 'transform 0.3s ease',
                                            backgroundColor: window.innerWidth <= 768 ? 'white' : 'white',
                                            padding: window.innerWidth <= 768 ? '10px' : '0', // Add padding for mobile
                                     
                                        }}
                                    >
                                        <div className="abuot-box row">
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb10" style={{ lineHeight: '32px' }}>
                                                Dharmender K Yadav
                                                    <span className="bg-primary"></span>
                                                </h2>
                                                <h4 className="text-gray-dark">National Team Member, GUESSS India
                                                </h4>
                                                {/* <p style={{ fontSize: '14px', lineHeight: '16px', textAlign: 'justify' }}>
                                                    Associate Professor [Finance & Entrepreneurship]
                                                    School of Management, Indian Institute of Technology Mandi
                                                    Ex-Director Incubation & Founding Faculty Incharge, IIT Mandi Catalyst
                                                    Himachal Pradesh 175005
                                                </p> */}
                                            </div>
                                            <div className="col-lg-8">
                                                <p style={{ fontSize: '16px', lineHeight: '18px', textAlign: 'justify' , marginTop:"20px" , marginLeft:"10px"}}>
                                                Dharmender K Yadav is a doctoral candidate at the School of Management at Indian Institute of Technology Mandi. His research interests include student entrepreneurship, entrepreneurship education, and geography of entrepreneurship. He has a Master’s Degree from Banaras Hindu University and an MPhil from the Delhi School of Economics. </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* About Us End */}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Homepage2;
