import React, { Component, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Masonry from 'react-masonry-component';

//images
import box1 from './../../../images/portfolio/image_1.png';
import box2 from './../../../images/portfolio/image_2.png';
import box3 from './../../../images/portfolio/Prof Isabella Hatak GUESSS core team.jpg';
import box4 from './../../../images/portfolio/image_3.png';
import box5 from './../../../images/portfolio/image_5.png';
import box6 from './../../../images/portfolio/image_4.png';
import box7 from './../../../images/portfolio/image_7.jpg';
import box8 from './../../../images/portfolio/image_8.jpg';

const imageBlog = [
    { Large: box5, tag: ['Web'], name: "Dr. Puran Singh", subname: "Country Delegate, GUESSS India", link: "./puransingh" },
    { Large: box6, tag: ['Web'], name: "Dharmendra Kumar Yadav", subname: "National Team Member", link: "./dharmendrayadav" },
];

//Light Gallery on icon click
const Iconimage = ({ link }) => {
    const { openLightbox } = useLightbox();
    return (
        <Link to={link} onClick={() => openLightbox()} className="mfp-link portfolio-fullscreen">
            <i className="ti-fullscreen icon-bx-xs"></i>
        </Link>
    );
}

// Masonry section
const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

// TagLi Component
const TagLi = ({ disp, name, handlesettag, tagActive }) => {
    return (
        <li className={`tag ${tagActive ? 'btn active' : 'btn'}`} onClick={() => handlesettag(name)}>
            <input type="radio" />
            <Link to={"#"} className="site-button-secondry radius-sm"><span>{disp}</span></Link>
        </li>
    );
};

class ProtfolioStyle3 extends Component {
    render() {
        return (
            <Fragment>
                <div className="section-full bg-white content-inner-1 portfolio">
                    <div className="container-fluid">
                        <div className="sort-title-bx" data-name="Element Style 3">Element Style 3 <i className="fa fa-angle-double-right"></i></div>
                        <PortfolioItem />
                    </div>
                </div>
            </Fragment>
        )
    }
}

function PortfolioItem() {
    const [tag, setTag] = useState('All');
    const [filteredImages, setFilteredImages] = useState([]);

    useEffect(() => {
        tag === 'All' ? setFilteredImages(imageBlog) : setFilteredImages(imageBlog.filter(image => image.tag.find(element => element === tag)));
    }, [tag]);

    return (
        <>
            <div className="max-w600 m-auto text-center m-b30">
                <h6 className="m-t0">Meet Our Team</h6>
                <h2 className="m-t0">Get to know our team of experts!!</h2>
            </div>
            <div className="site-filters clearfix center m-b40">
                <ul className="filters" data-toggle="buttons">
                    <TagLi disp="GUESSS INDIA TEAM" name="Web" handlesettag={setTag} tagActive={tag === 'Web'} />
                    <TagLi disp="AMBASSADORS" name="Branding" handlesettag={setTag} tagActive={tag === 'Branding'} />
                </ul>
            </div>
            <div className="clearfix">
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1">
                            <Masonry
                                className={'my-gallery-class'}
                                options={masonryOptions}
                                disableImagesLoaded={false}
                                updateOnEachImageLoad={false}
                                imagesLoadedOptions={imagesLoadedOptions}
                            >
                                <ul style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'no wrap',
                                    padding: '0',
                                    margin: '0',
                                    listStyle: 'none'
                                }}>
                                    {filteredImages.map((item, index) => (
                                        <li
                                            className="card-container col-lg-3 col-md-6 col-xs-6 col-sm-6 p-a0"
                                            key={index}
                                            style={{
                                                margin: '15px',
                                                listStyle: 'none'
                                            }}
                                        >
                                            <div className="dlab-box dlab-gallery-box">
                                                <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                                    <img src={item.Large} alt="" />
                                                    <div className="overlay-bx">
                                                        <div className="overlay-icon align-b text-white text-left">
                                                            <div className="text-white text-left port-box">
                                                                <h5><Link to={item.link} className="custom-link">{item.name}</Link></h5>
                                                                <p><Link to={item.link}  className="custom-link">{item.subname}</Link></p>
                                                                <Iconimage link={item.link} /> {/* Pass the link here */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </Masonry>
                        </ul>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </div>
        </>
    );
}

export { PortfolioItem };
export default ProtfolioStyle3;
