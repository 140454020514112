import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import Sidebar from './../../Element/Sidebar';

import bnr from './../../../images/banner/bnr5.jpg';
import thum1 from './../../../images/blog/default/thumb11.webp';
import grid4 from './../../../images/blog/grid/pic4.jpg';
import grid1 from './../../../images/blog/grid/pic1.jpg';
import test1 from './../../../images/testimonials/pic1.jpg';
import test2 from './../../../images/testimonials/pic2.jpg';
import test3 from './../../../images/testimonials/pic3.jpg';

class BlogDetails2 extends Component {


    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" , height:"32vh" }}>
						<PageTitle motherMenu='Why GUESSS India?' activeMenu='Blog' />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-xl-9">
                                    <div className="blog-post blog-single">
                                        <div className="dlab-post-title ">
                                            <h4 className="post-title m-t0"><Link to={"#"}>Indian Students  and  the  Entrepreneurial Spirit: Key Takeaways from the GUESSS India 2023 Report 
                                            </Link></h4>
                                        </div>
                                        <div className="dlab-post-meta m-b20">
                                            <ul className="d-flex align-items-center">
                                                <li className="post-date"> <i className="fa fa-calendar"></i><strong>10 Oct</strong> <span> 2024</span> </li>
                                                <li className="post-author"><i className="fa fa-user"></i>By<Link to={"#"}> Javeed Dar</Link> </li>
                                                {/* <li className="post-comment"><i className="fa fa-comments"></i><Link to={"#"}>0 Comments</Link></li> */}
                                            </ul>
                                        </div>
                                        <div className="dlab-post-media dlab-img-effect zoom-slow">
											<Link to={"#"}><img src={thum1} style={{height:'500px'}}alt="" /></Link>
										</div>
                                        <div className="dlab-post-text">
                                        <blockquote>
                                            “As the startup ecosystem in India continues to thrive, the latest GUESSS India 2023 report has revealed a telling insight: Indian students, once largely focused on traditional employment, are now increasingly setting their sights on entrepreneurship as a long-term career choice. The survey offers an illuminating look at this shift, providing policymakers, educational institutions, and the corporate world with crucial data to guide the future of India’s workforce”.
											</blockquote>
                                            <p style={{textAlign:"justify"}}> At the heart of every nation’s progress lies the potential of its youth. India's youth is on the cusp of an entrepreneurial revolution. As India positions itself to become the world’s third-largest economy, the career aspirations of its students are shifting. The GUESSS India Survey report 2023 offers a fascinating window into this transformation, it examines these transformations in an interesting way and explains how though a large number of students continue to seek traditional employment after graduating, a growing number are embracing world of entrepreneurship. This isn't just a trend; it's a movement.
</p>
<p style={{textAlign:"justify"}}>The report led by Prof Puran Singh, at Indian institute of technology Mandi,   provides valuable insights into the entrepreneurial intention, attitude and behaviour of Indian students enrolled in higher education institutions, emphasizing  that students in India are not only envisioning bigger goals but also actively strategizing on how to transform those aspirations into actual achievements. For policymakers, educators, and business leaders, this presents a critical juncture that demands prompt action to nurture and uphold this entrepreneurial spirit.
</p>
                                            <h5>A Shift in Career Aspirations
                                            </h5>  <p>
                                            The GUESSS India 2023 survey reveals a striking shift in the career intentions of Indian students. Immediately after graduation, a significant 69.7% of students plan to enter traditional employment, but five years later, that number drops dramatically to 52.2%. In contrast, the desire to start entrepreneurial ventures more than doubles, from 14% at graduation to 31.4% five years down the line.
</p>
                                           <p>This data presents a remarkable shift: while students may initially seek the stability of a traditional job, their long-term aspirations are increasingly entrepreneurial. The draw of entrepreneurship, where innovation meets independence, is becoming more irresistible to India's youth as they envision themselves creating something new and lasting.
                                           </p>
                                           <h5>The Entrepreneurial Tide is Rising
                                           </h5>
                                            
                                            <p>
                                            The proportion of students intending to become entrepreneurs grows significantly over time. Students with family business background also demand a higher preference for entrepreneurship , With 15.6% intending to start their own ventures upon graduation, compared to 12.8% of students without a business background. 26% of Indian students are linked to an incubation centre , indicating they have access to support services  and approximately 49% of students have engaged in entrepreneurship courses higher than the global average of 41.2% . 

                                            </p>
                                            <h5>Global Trends, Local Insights

                                           </h5>
                                            
                                            <p>
                                            This shift is not unique to India. Globally, the GUESSS 2023 report reveals similar trends, with 65.9% of students worldwide planning to start their careers as employees immediately after graduation, and 53.3% intending to remain in traditional employment five years later. Meanwhile, the proportion of students planning to become founders globally jumps from 15.7% to 30% over the same period.  However, India’s numbers tell a particularly compelling story. The entrepreneurial inclination of Indian students is rapidly catching up with global figures, driven by a unique combination of policy support, cultural shifts, and the country's own booming start-up ecosystem.


                                            </p>
                                            <h5>
A Supportive Environment for Aspiring Entrepreneurs

</h5>
 
 <p>
 Several factors contribute to the entrepreneurial ambitions of students in India. The country’s startup ecosystem is thriving, bolstered by government efforts to foster innovation through programs such as Start-up India and the New Education Policy (NEP), which advocates for entrepreneurship within higher education. Additionally, educational initiatives that incorporate hands-on entrepreneurial training into their curricula, along with increasing media coverage of successful start-ups, are motivating a new wave of individuals to explore the possibility of establishing their own businesses. Such innitiatives are playing pivotal role in making entrepreneurship a mainstream aspiration, bringing start-up culture into Indian living rooms and motivating students to think big. These influences, coupled with India's recent growth as a global start-up hub with more than 100 unicorns, have provided a solid foundation for students to see entrepreneurship as a viable, even desirable, career path.
 </p> <h5>
 Beyond the Classroom:  What Policymakers Can Do
</h5>
 
 <p>
 The GUESSS India 2023 report is not just a snapshot of student aspirations—it is a call to action for policymakers and educational institutions. To maintain and fuel this entrepreneurial drive, specific interventions are needed:
 </p>
                                            <ol><li> <span style={{fontWeight:"bolder"}}>Enhanced Entrepreneurial Education:</span> The growing entrepreneurial aspirations among students highlight the importance of integrating entrepreneurship education and practical experiences into academic curricula. That means , Universities and colleges need to focus on hands-on learning, offering start-up incubation centers, mentoring programs, and collaboration with industry leaders. Entrepreneurship must be an integral part of the education system, allowing students to experiment and innovate.

                                            </li>
                                            <li><span style={{fontWeight:"bolder"}}>Cultivating Entrepreneurial Spirit : </span>There needs to be a cultural shift in how society views entrepreneurship. While shows like Shark Tank India have helped, we need more initiatives that celebrate and support entrepreneurship as a legitimate, respected career option, rather than a risky alternative to traditional employment. India must persist it’s efforts through awareness programes, bootcamps  , Competitions to motivate students to consider entrepreneurship as viable career path.  
                                            </li>
                                            <li> <span style={{fontWeight:"bolder"}}>A Simplified Regulatory Framework: </span> Making it easier to start and operate a business in India should be a priority. Simplified tax laws, easier access to licenses, and reduced bureaucratic red tape can help more young entrepreneurs take the plunge.
                                            </li>
                                            </ol>
                                            
                                            <div className="dlab-divider bg-gray-dark"></div>
                                          
                                            <h5>

                                            Conclusion: A New Generation of Innovators

</h5>
                                            <p>
                                            The GUESSS India 2023 report highlights a significant evolution in the career aspirations of Indian students. While many still begin their professional journey in traditional employment, the lure of entrepreneurship is growing stronger. Policymakers, educators, and the private sector must come together to support this transition, creating an ecosystem where students can easily move from corporate roles to entrepreneurial ventures.
India’s future is entrepreneurial, and this generation of students is ready to lead the charge. By harnessing their passion, creativity, and drive, India has the potential to become not just a global economic powerhouse but a leader in innovation and entrepreneurial excellence. The entrepreneurial spirit of India's youth is not just a trend—it’s a movement that could define the nation’s future. </p>
                                        </div>
                                        {/* <div className="dlab-post-tags clear">
                                            <div className="post-tags">
												<Link to={"#"}>Child </Link> 
												<Link to={"#"}>Eduction </Link> 
												<Link to={"#"}>Money </Link>
												<Link to={"#"}>Resturent </Link> 
											</div>
                                        </div> */}
                                       
                                        {/* <div className="share-details-btn">
                                            <ul>
                                                <li><h5 className="m-a0">Share Post</h5></li>
                                                <li><Link to={"#"} className="site-button facebook button-sm"><i className="fa fa-facebook"></i> Facebook</Link></li>
                                                <li><Link to={"#"} className="site-button google-plus button-sm"><i className="fa fa-google-plus"></i> Google Plus</Link></li>
                                                <li><Link to={"#"} className="site-button linkedin button-sm"><i className="fa fa-linkedin"></i> Linkedin</Link></li>
                                                <li><Link to={"#"} className="site-button instagram button-sm"><i className="fa fa-instagram"></i> Instagram</Link></li>
                                                <li><Link to={"#"} className="site-button twitter button-sm"><i className="fa fa-twitter"></i> Twitter</Link></li>
                                                <li><Link to={"#"} className="site-button whatsapp button-sm"><i className="fa fa-whatsapp"></i> Whatsapp</Link></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="clear" id="comment-list">
                                        <div className="comments-area" id="comments">
                                            <h2 className="comments-title">8 Comments</h2>
                                            <div className="clearfix m-b20">
                                                <ol className="comment-list">
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard"> <img className="avatar photo" src={test1} alt="" /> 
															<cite className="fn">Stacy poe</cite> <span className="says">says:</span> </div>
                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neqnsectetur adipiscing elit. Nam viae neqnsectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                        </div>
                                                        <ol className="children">
                                                            <li className="comment odd parent">
                                                                <div className="comment-body">
                                                                    <div className="comment-author vcard"> 
																		<img className="avatar photo" src={test2} alt="" />
																		<cite className="fn">Stacy poe</cite> <span className="says">says:</span>
																	</div>
                                                                    <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                                    <p>
																		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molesti.Sed vestibulum sem felis, ac elementum ligula blandit ac.
																	</p>
                                                                    <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                                </div>
                                                                <ol className="children">
                                                                    <li className="comment odd parent">
                                                                        <div className="comment-body">
                                                                            <div className="comment-author vcard"> <img className="avatar photo" src={test3} alt="" /> <cite className="fn">Stacy poe</cite> <span className="says">says:</span> </div>
                                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                                            <p>
																				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
																			</p>
                                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                                        </div>
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard"> 
																<img className="avatar photo" src={test3} alt="" />
																<cite className="fn">Stacy poe</cite> <span className="says">says:</span> 
															</div>
                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link></div>
                                                        </div>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard">
																<img className="avatar photo" src={test2} alt="" /> 
																<cite className="fn">Stacy poe</cite> <span className="says">says:</span>
															</div>
                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                        </div>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard"> 
																<img className="avatar photo" src={test3} alt="" /> 
																<cite className="fn">Stacy poe</cite> <span className="says">says:</span> 
															</div>
                                                            <div className="comment-meta"> 99<Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                        </div>
                                                    </li>
                                                </ol>
                                                <div className="comment-respond" id="respond">
                                                    <h4 className="comment-reply-title" id="reply-title">Leave a Reply 
														<small>
															<Link to={"#"} className="d-none"  id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> 
														</small>
													</h4>
                                                    <form className="comment-form" id="commentform" method="post" action="http://sedatelab.com/developer/donate/wp-comments-post.php">
                                                        <p className="comment-form-author">
                                                            <label for="author">Name <span className="required">*</span></label>
                                                            <input type="text" value="Author" name="Author" placeholder="Author" id="author" />
                                                        </p>
                                                        <p className="comment-form-email">
                                                            <label for="email">Email <span className="required">*</span></label>
                                                            <input type="text" value="email" placeholder="Email" name="email" id="email" />
                                                        </p>
                                                        <p className="comment-form-url">
                                                            <label for="url">Website</label>
                                                            <input type="text" value="url" placeholder="Website" name="url" id="url" />
                                                        </p>
                                                        <p className="comment-form-comment">
                                                            <label for="comment">Comment</label>
                                                            <textarea rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
                                                        </p>
                                                        <p className="form-submit">
                                                            <input type="submit" value="Post Comment" className="submit" id="submit" name="submit" />
                                                        </p>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-lg-4 col-xl-3 sticky-top">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}
export default BlogDetails2;