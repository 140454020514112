import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import { Accordion, } from 'react-bootstrap';
	
const defaultAccordion = [

	
	  {
		title: '11. How often is the GUESSS survey conducted?',
		text:
		  'The GUESSS survey is conducted biennially (every two years), allowing for longitudinal analysis to track changes in entrepreneurial intentions and activities over time. The next round of the GUESSS India Survey is scheduled for 2025, providing opportunities to observe trends and developments in student entrepreneurship.',
	  },
	  {
		title: '12. What are the key findings of the GUESSS India 2023 Report?',
		text:
		  '38% of Indian students are involved in the venture creation process, with 33% in the nascent stage and 4.8% actively running revenue-generating businesses.70% of students plan to become employees upon graduation, but this drops to 52% over the next five years, as 31% aim to become entrepreneurs.Indian students show the highest entrepreneurial intent globally, with a score of 4.6 on a 7-point scale, compared to the global average of 3.7. 63% of student entrepreneurs report receiving university support for their ventures.',
	  },
	  {
		title: '13. How does the GUESSS India 2023 Report contribute to understanding the Indian startup ecosystem?',
		text:
		  'The report provides a detailed analysis of student entrepreneurial aspirations, highlighting opportunities and challenges in nurturing the next generation of entrepreneurs in India. It helps universities, incubators, policymakers, and investors understand key trends and areas where more support is needed to turn entrepreneurial intent into successful ventures.',
	  },
	  {
		title: '14. How does India compare to other countries in the GUESSS survey?',
		text:
		  'India ranks highly in terms of entrepreneurial intent and nascent venture activity among top startup economies. However, it lags behind its global peers, such as the US and Germany, in terms of active ventures - students running revenue-generating businesses. This reflects a strong entrepreneurial pipeline but also indicates the need for enhanced support to bridge the gap between intent and execution.',
	  },
	  {
		title: '15. Why is the GUESSS India 2023 Report important for universities and educators?',
		text:
		  'The report highlights the crucial role universities play in fostering entrepreneurship among students. It reveals that 63% of student entrepreneurs receive university support, and students rate the university entrepreneurship climate in India as highly positive (4.7/7). The findings offer universities a roadmap for enhancing their entrepreneurship curricula, programs, and incubation facilities to better support student ventures.',
	  },
	  {
		title: '16. What role does incubation play in student entrepreneurship according to the report?',
		text:
		  'The report reveals that 26% of student ventures are incubated, either at universities or through external incubators. While a significant portion of nascent ventures remain outside incubation programs, the data highlights the importance of incubators in providing mentorship, funding, and networking opportunities, which are critical for turning ideas into viable businesses.',
	  },
	  {
		title: '17. What recommendations does the report make for improving student entrepreneurship in India?',
		text:
		  'The report emphasizes the need for: Expanding incubation support to more student ventures, especially those in the nascent stage. Integrating entrepreneurship more deeply into academic curricula to equip students with the skills needed to build successful ventures. Encouraging entrepreneurial activities during students academic journeys through flexible academic and administrative provisions. Leveraging India robust entrepreneurial intent to support student-led ventures in becoming revenue-generating businesses.',
	  },
	  {
		title: '18. How can stakeholders, such as policymakers and investors, benefit from the findings of the GUESSS India 2023 Report?',
		text:
		  'Policymakers can use the report to craft targeted interventions that encourage student entrepreneurship, such as expanding incubation programs and increasing access to early-stage funding. Investors can identify emerging trends and opportunities within the student entrepreneurship ecosystem and support high-potential ventures at the early stage.',
	  },
	  {
		title: '19. What impact does the GUESSS India 2023 Report have on the future of India’s startup ecosystem?',
		text:
		  'The report demonstrates the vast potential of India’s student entrepreneurs to contribute to the country’s startup ecosystem. With nearly one-third of students aspiring to become entrepreneurs within five years, the findings highlight the need for continued investment in education, incubation, and policy support to cultivate the next generation of successful founders.',
	  },
	  {
		title: '20. How can the media and stakeholders access the full GUESSS India 2023 Report?',
		text:
		  'The full report will be available for download following the official launch at ISBAcon 2024 in Hyderabad on October 18, 2024. For more information or to get a copy of the report, please visit www.guesssindia.in',
	  },
		
]	
const AccordionBlog = () => {
	const [activeDefault, setActiveDefault] = useState(0)
	return(
		<Accordion className="accordion dlab-accordion faq-1 box-sort-in m-b30"  defaultActiveKey="-1">
			{defaultAccordion.map((d, i) => (
			   <div className="panel">
					<div className="acod-head">
						<h6 className="acod-title">
							<Accordion.Toggle as={Link} variant="link"
								className={` ${ activeDefault === i ? '' : 'collapsed'}`}
								onClick={() =>
									setActiveDefault(activeDefault === i ? -1 : i)
								} eventKey={`${i}`}>	
								 {d.title}		
							</Accordion.Toggle>
						</h6>	
					</div>
						
				<Accordion.Collapse eventKey={`${i}`} className="acod-body">
				  <div className="acod-content">
					{d.text}
					</div>
				</Accordion.Collapse>
			  </div>
			))}
		</Accordion>	
	)
}
export {defaultAccordion};
export default AccordionBlog;