import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase'; 
import { collection, addDoc } from 'firebase/firestore';
const Footer1 = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleSubscribeClick = (e) => {
        e.preventDefault(); 
        setShowPopup(true); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userData = { email, name, phone };
        console.log('Submitting user data:', userData); // Log data being sent
    
        try {
            // Add a new document with a generated ID in the 'subscribers' collection
            await addDoc(collection(db, 'subscribers'), userData);
            console.log('User data submitted successfully!');
            setShowPopup(false); // Close popup after successful submission
            // Optionally reset the form fields
            setEmail('');
            setName('');
            setPhone('');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <footer className="site-footer text-uppercase">
                <div className="footer-top bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget widget_services border-0">
                                    <h5 className="m-b30 text-white">Useful Links</h5>
                                    <ul>
                                        <li><Link to={'./about-1'}>About Us</Link></li>
                                      
                                        <li><Link to={'./contact'}>Send a Message</Link></li>
                                        <li><Link to={'./blog-standard-right-sidebar'}>Latest Blog</Link></li>
                                        <li><Link to={'./about-2'}>Be our partner</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget widget_services border-0">
                                    <h5 className="m-b30 text-white">More</h5>
                                    <ul>
                                     
                                        <li><Link to={'./about-1'}>Guesss India Team</Link></li>
                                     
                                       
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget widget_getintuch">
                                    <h5 className="m-b30 text-white">Contact us</h5>
                                    <ul>
                                        <li><i className="ti-location-pin"></i><strong>address</strong> School of Management, North Campus, IIT Mandi, Kamand, Distt. Mandi H.P. 175005</li>
                                        <li style={{ textTransform: 'lowercase' }}><i className="ti-email"></i><strong>email</strong>support@guesssindia.in</li>
                                    </ul>
                                </div>
                            </div>
                        
                            <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget">
                                    <h5 className="m-b30 text-white">Subscribe for the Report 2023</h5>
                                    <p className="text-capitalize m-b20"> <span style={{ textTransform: 'none' }}>Stay updated with the latest insights from the GUESSS 2023 Report. Subscribe now to access key findings on global entrepreneurial trends, student career aspirations, and more!</span></p>
                                    <div className="subscribe-form m-b20">
                                        <form className="dzSubscribe" onSubmit={handleSubscribeClick}>
                                            <div className="dzSubscribeMsg"></div>
                                            <div className="input-group">
                                                <input
                                                    name="dzEmail"
                                                    required
                                                    className="form-control"
                                                    placeholder="Your Email Id"
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <span className="input-group-btn">
                                                    <button name="submit" value="Submit" type="submit" className="site-button">Subscribe</button>
                                                </span>
                                            </div>
                                        </form>

                                        {showPopup && (
                                            <div className="popup">
                                                <div className="popup-content">
                                                    <h2>Enter your details</h2>
                                                    <form onSubmit={handleSubmit}>
                                                        <input
                                                            type="text"
                                                            placeholder="Your Name"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            required
                                                        />
                                                        <input
                                                            type="tel"
                                                            placeholder="Your Phone Number"
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            required
                                                        />
                                                        <button type="submit">Submit</button>
                                                        <button type="button" onClick={() => setShowPopup(false)}>Cancel</button>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <ul className="list-inline m-a0">
                                        <li><a href={"https://www.linkedin.com/in/guesss-survey-0567812a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} className="site-button linkedin circle mr-1"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href={"https://www.instagram.com/guesssindia?igsh=MTB0eGg2MnRoNGE1Yg=="} className="site-button instagram circle mr-1"><i className="fa fa-instagram"></i></a></li>
                                        <li>
        <a href="https://x.com/GIndia41366?t=jfkg8Lq8M8rY1BpvMX7_ew&s=09" className="site-button circle mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24" style={{marginTop:"10px"}}>
                <path d="M24 2v1.75l-9.313 10.25L24 24h-4.25L12 14.875 4.25 24H0l9.313-10L0 3.75V2h4.25l7.75 8.875L19.75 2H24Z"/>
            </svg>
        </a>
    </li>
    <li>
        <a href="https://whatsapp.com/channel/0029Vas73zVKWEKx3bV6q91c" className="site-button whatsapp circle mr-1">
            <i className="fa fa-whatsapp"></i>
        </a>
    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 text-left"> <span>Copyright © 2024 GUESSS INDIA</span> </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 text-right">
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <style jsx>{`
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    .popup-content {
        background: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 300px;
        text-align: center;
    }
    .popup-content h2 {
        margin-bottom: 15px;
    }
    .popup-content input {
        width: calc(100% - 20px);
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333; /* Set visible text color */
        background-color: white; /* Ensure input background is white */
    }
    .popup-content input::placeholder {
        color: #888; /* Placeholder color for better visibility */
    }
    .popup-content button {
        padding: 10px 15px;
        margin: 5px;
        border: none;
        border-radius: 4px;
        background-color: #6cc000; /* Matching your button background */
        border-color: #6cc000;
        color: white;
        cursor: pointer;
    }
    .popup-content button:hover {
        background-color: #5cb85c;
    }
    `}</style>
        </>
    );
};

export default Footer1;
