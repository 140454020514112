import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Service from './Service/Service';

import ImgCarousel from './../Element/ImgCarousel'
import ClientCarousel from './../Element/ClientCarousel'

//Images 
import bnr1 from './../../images/banner/bnr2.jpg';

class ProjectDetails extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <>
              
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Project Details --> */}
                        <div className="section-full ">
                            <div className="container">
                            <div className="section-head text-black text-center m-t0">
                                    {/* <h4 className="text-gray-dark m-b10">GUESSS Project</h4> */}
                                    <h2 className="box-title m-tb0">GUESSS India<span className="bg-primary"></span></h2>
                                    <hr></hr>
                                    </div>
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        {/* <h2 className="m-b10 m-t0">GUESSS India</h2> */}
                                        <h5 className="m-b20">GUESSS India is the India Chapter of the Global University Entrepreneurial Spirit Students' Survey (GUESSS) 
                                        </h5>
                                        <p className="m-b10" style={{textAlign:"justify"}}>GUESSS is the largest global research project that involves a comprehensive survey on student entrepreneurs worldwide. The project aims to study entrepreneurial spirit - intentions and activities of students across the globe. Currently, Professor Puran Singh at the School of Management, Indian Institute of Technology Mandi, is leading the GUESSS India Chapter.</p>
                                        <p className="m-b10" style={{textAlign:"justify"}}>GUESSS India 2023 marks the maiden effort to survey Indian students’ entrepreneurial spirit. GUESSS report presents the findings of the survey, revealing the entrepreneurial mindset of Indian students for the first time. This web-based survey was conducted during November 2023-February 2024 and received 13,896 responses. It covers a broad range of variables, including students' career choices, entrepreneurial intentions, entrepreneurial attitude, entrepreneurial behaviour, university climate, and sociodemographic profiles of the students. These variables enable insights into both global and national trends of student entrepreneurship.</p>
                                        {/* <Link to={"#"} className="site-button radius-xl outline">Read More <i className="fa fa-long-arrow-right"></i></Link> */}
                                    </div>
                                    <div className="col-lg-6 col-md-6">
										<ImgCarousel /> 
                                    </div>
                                </div>
                                <div className="row m-b50">
                                    <div className="col-md-12 m-b20">
                                        <div className="p-a30 bg-gray">
                                            <h2>Research Direction </h2><p  style={{textAlign:"justify"}}>GUESSS India's unwavering commitment to understanding student entrepreneurship has paved the way for a treasure trove of insights. Our research encompasses multidimensional exploration, shedding light on the aspirations, challenges, and motivations that fuel the entrepreneurial spirit of India's youth.</p>
                                            <Service/>
                                        </div>
                                        
                                    </div>
                                    
                                   
                                </div>
                            </div>
                          {/*   <div className="container">
                                <div className="row m-b30">
                                    <div className="col-lg-4 col-md-12 align-self-center">
                                        {/* <h5>Who We Are</h5> */}
                                        {/* <h2 className="font-weight-700">GUESSS India 2023 highlights</h2>
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="100" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">31.4%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Entrepreneurs 5-Years After Studies</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="49" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">14%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Entrepreneurs Direct After Studies</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="65" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">33%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Nascent Student Entrepreneur</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */} 
                           
                        </div>
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
                
               
            </>
        )
    }
}
export default ProjectDetails;