import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhP9CVkP_pu1I_2YmAmEy52RZ1-7d-lac",
  authDomain: "guessss.firebaseapp.com",
  projectId: "guessss",
  storageBucket: "guessss.appspot.com",
  messagingSenderId: "758463186776",
  appId: "1:758463186776:web:37c26fabbfa97cc3e1c72d",
  measurementId: "G-FK412N6E0Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); // Initialize Analytics
const db = getFirestore(app); // Initialize Firestore

// Export the Firestore instance
export { db };