import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import Sidebar from './../../Element/Sidebar';

import bnr from './../../../images/banner/bnr5.jpg';
import thum1 from './../../../images/blog/default/thum2.jpg';
import grid4 from './../../../images/blog/grid/pic4.jpg';
import grid1 from './../../../images/blog/grid/pic1.jpg';
import test1 from './../../../images/testimonials/pic1.jpg';
import test2 from './../../../images/testimonials/pic2.jpg';
import test3 from './../../../images/testimonials/pic3.jpg';

class BlogDetails extends Component {


    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" , height:"32vh" }}>
						<PageTitle motherMenu='Why GUESSS India?' activeMenu='Blog' />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-xl-9">
                                    <div className="blog-post blog-single">
                                        <div className="dlab-post-title ">
                                            <h4 className="post-title m-t0"><Link to={"#"}>Why GUESSS India ? Igniting the Entrepreneurial Spirit of Indian students </Link></h4>
                                        </div>
                                        <div className="dlab-post-meta m-b20">
                                            <ul className="d-flex align-items-center">
                                                <li className="post-date"> <i className="fa fa-calendar"></i><strong>14 Oct</strong> <span> 2024</span> </li>
                                                <li className="post-author"><i className="fa fa-user"></i>By<Link to={"#"}> Javeed Dar</Link> </li>
                                                {/* <li className="post-comment"><i className="fa fa-comments"></i><Link to={"#"}>0 Comments</Link></li> */}
                                            </ul>
                                        </div>
                                        <div className="dlab-post-media dlab-img-effect zoom-slow">
											<Link to={"#"}><img src={thum1} style={{height:'500px'}}alt="" /></Link>
										</div>
                                        <div className="dlab-post-text">
                                        <blockquote>
                                            “The GUESSS India 2023 survey is not just a report; it’s a call to action for all stakeholders invested in the future of India’s startup ecosystem. If we heed its insights, India’s youth can indeed become the entrepreneurs that drive the nation’s next wave of growth and innovation”.
											</blockquote> <p>
											In an age where innovation and entrepreneurship are key building blocks of global economies, understanding the entrepreneurial spirit and career aspirations of the students is crucial. For a country like India with 66 percent population under the age of 35, the choices of these young minds will shape indias’ future. As the future generation of entrepreneurs is studying in institutions of higher education, their mindset holds key to the prosperity of the economy and the country . To understand  what is inside the minds of Indian students - The GUESSS India 2023 Survey Report, a comprehensive survey captures this entrepreneurial spirit, offering rich insights into the career intentions of over 13,896 students across India. But why was this survey necessary, and what does it reveal about the aspirations of Indian students?</p>
                                            <h5>The Need of GUESSS India</h5>  <p>
                                            India is at a crossroads. While the country is a burgeoning global startup hub with over 100 unicorns, there remains a gap in understanding how deep the entrepreneurial spirit runs among India’s youth. Traditional surveys often focus on employment statistics or overall economic trends, but the GUESSS India survey stands out by specifically exploring the entrepreneurial intentions of students at two key points in their lives—immediately after graduation and five years later.</p>
                                           <p>This focus on students is vital. They are the workforce of tomorrow, the future innovators, job creators, and economic drivers. By understanding their career aspirations  policymakers, educators, and businesses can better tailor their support systems to foster the next wave of Indian entrepreneurs. GUESSS India is part of the global Global University Entrepreneurial Spirit Students' Survey (GUESSS), an international research project that surveys students across more than 50 countries. It aims to uncover insights into students' career choices and entrepreneurial intentions, making it one of the most comprehensive studies of its kind. What makes GUESSS India unique is the depth of its local focus, providing granular data specific to the Indian context while aligning with global trends. This dual perspective—global and local—makes GUESSS India a key resource for understanding how India’s youth are positioned in the global entrepreneurial landscape.</p>
                                           <h5>GUESSS India 2023: Key figures and findings</h5>
                                            <img className="alignleft" width="300" src={grid4} alt="" />
                                            <p>
                                            The GUESSS India 2023 survey, conducted across 100 institutions , engaged 13,800+ students to gauge their career intentions. The data revealed both reassuring and surprising trends. Here are the key takeaways:
                                            </p>
                                            <ul><li>69.7% of students plan to start their careers as employees immediately after graduation, with 44.9% intending to work in large businesses, 18.3% in public service, and 15.2% in academia.
                                            </li>
                                            <li>Only 14% of students expressed a desire to start their own business immediately after graduation, but this figure more than doubles to 31.4% five years later, indicating a growing interest in entrepreneurship over time.
                                            </li>
                                            <li>3.8% of students plan to join their family business upon graduation, with this figure rising to 6.6% five years later.
                                            </li>
                                            <li>Over time, students' interest in employment decreases, with only 52.2% intending to remain employees five years after graduation, compared to 69.7% initially.
                                            </li></ul>
                                            
                                            <div className="dlab-divider bg-gray-dark"></div>
                                            <img className="alignright" width="300" src={grid1} alt="" />
                                            <p>
                                            This shift indicates a growing entrepreneurial mindset among Indian students, aligning with global trends. The GUESSS Global 2023 survey similarly showed that while 65.9% of students globally intended to be employees immediately after their studies, only 53.3% maintained that intention five years later. The proportion of students planning to become entrepreneurs globally also nearly doubled from 15.7% to 30%, similar to India’s rise from 14% to 31.4%.</p>
                                        </div>
                                        {/* <div className="dlab-post-tags clear">
                                            <div className="post-tags">
												<Link to={"#"}>Child </Link> 
												<Link to={"#"}>Eduction </Link> 
												<Link to={"#"}>Money </Link>
												<Link to={"#"}>Resturent </Link> 
											</div>
                                        </div> */}
                                        <div className="dlab-divider bg-gray-dark op4"><i className="icon-dot c-square"></i></div>
                                        {/* <div className="share-details-btn">
                                            <ul>
                                                <li><h5 className="m-a0">Share Post</h5></li>
                                                <li><Link to={"#"} className="site-button facebook button-sm"><i className="fa fa-facebook"></i> Facebook</Link></li>
                                                <li><Link to={"#"} className="site-button google-plus button-sm"><i className="fa fa-google-plus"></i> Google Plus</Link></li>
                                                <li><Link to={"#"} className="site-button linkedin button-sm"><i className="fa fa-linkedin"></i> Linkedin</Link></li>
                                                <li><Link to={"#"} className="site-button instagram button-sm"><i className="fa fa-instagram"></i> Instagram</Link></li>
                                                <li><Link to={"#"} className="site-button twitter button-sm"><i className="fa fa-twitter"></i> Twitter</Link></li>
                                                <li><Link to={"#"} className="site-button whatsapp button-sm"><i className="fa fa-whatsapp"></i> Whatsapp</Link></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <div className="clear" id="comment-list">
                                        <div className="comments-area" id="comments">
                                            <h2 className="comments-title">8 Comments</h2>
                                            <div className="clearfix m-b20">
                                                <ol className="comment-list">
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard"> <img className="avatar photo" src={test1} alt="" /> 
															<cite className="fn">Stacy poe</cite> <span className="says">says:</span> </div>
                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neqnsectetur adipiscing elit. Nam viae neqnsectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                        </div>
                                                        <ol className="children">
                                                            <li className="comment odd parent">
                                                                <div className="comment-body">
                                                                    <div className="comment-author vcard"> 
																		<img className="avatar photo" src={test2} alt="" />
																		<cite className="fn">Stacy poe</cite> <span className="says">says:</span>
																	</div>
                                                                    <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                                    <p>
																		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molesti.Sed vestibulum sem felis, ac elementum ligula blandit ac.
																	</p>
                                                                    <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                                </div>
                                                                <ol className="children">
                                                                    <li className="comment odd parent">
                                                                        <div className="comment-body">
                                                                            <div className="comment-author vcard"> <img className="avatar photo" src={test3} alt="" /> <cite className="fn">Stacy poe</cite> <span className="says">says:</span> </div>
                                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                                            <p>
																				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
																			</p>
                                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                                        </div>
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard"> 
																<img className="avatar photo" src={test3} alt="" />
																<cite className="fn">Stacy poe</cite> <span className="says">says:</span> 
															</div>
                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link></div>
                                                        </div>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard">
																<img className="avatar photo" src={test2} alt="" /> 
																<cite className="fn">Stacy poe</cite> <span className="says">says:</span>
															</div>
                                                            <div className="comment-meta"> <Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                        </div>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-body">
                                                            <div className="comment-author vcard"> 
																<img className="avatar photo" src={test3} alt="" /> 
																<cite className="fn">Stacy poe</cite> <span className="says">says:</span> 
															</div>
                                                            <div className="comment-meta"> 99<Link to={"#"}>October 6, 2015 at 7:15 am</Link> </div>
                                                            <p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, ac elementum ligula blandit ac.
															</p>
                                                            <div className="reply"><Link to={"#"} className="comment-reply-link">Reply</Link> </div>
                                                        </div>
                                                    </li>
                                                </ol>
                                                <div className="comment-respond" id="respond">
                                                    <h4 className="comment-reply-title" id="reply-title">Leave a Reply 
														<small>
															<Link to={"#"} className="d-none"  id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> 
														</small>
													</h4>
                                                    <form className="comment-form" id="commentform" method="post" action="http://sedatelab.com/developer/donate/wp-comments-post.php">
                                                        <p className="comment-form-author">
                                                            <label for="author">Name <span className="required">*</span></label>
                                                            <input type="text" value="Author" name="Author" placeholder="Author" id="author" />
                                                        </p>
                                                        <p className="comment-form-email">
                                                            <label for="email">Email <span className="required">*</span></label>
                                                            <input type="text" value="email" placeholder="Email" name="email" id="email" />
                                                        </p>
                                                        <p className="comment-form-url">
                                                            <label for="url">Website</label>
                                                            <input type="text" value="url" placeholder="Website" name="url" id="url" />
                                                        </p>
                                                        <p className="comment-form-comment">
                                                            <label for="comment">Comment</label>
                                                            <textarea rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
                                                        </p>
                                                        <p className="form-submit">
                                                            <input type="submit" value="Post Comment" className="submit" id="submit" name="submit" />
                                                        </p>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-lg-4 col-xl-3 sticky-top">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}
export default BlogDetails;