import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import CountUp from 'react-countup';
import TestimonialCarousel from './../Element/TestimonialCarousel';
import Faqs from './Faqs';
//Carousel 
import TestimonialStyle2 from './ShortCode/TestimonialStyle2';
import LatestBlogSlider from './../Element/LatestBlogSlider';
import Index7Slider from './../Element/Index7Slider';
import Index7ClientCarousel from './../Element/Index7ClientCarousel';


//Images
import about1 from './../../images/about-us/img4.webp'; 
import about2 from './../../images/about-us/img2.webp'; 
import about3 from './../../images/about-us/img3.webp';

import services1  from './../../images/our-services/pic1.webp';
import services2  from './../../images/our-services/pic2.webp';
import services3  from './../../images/our-services/pic3.webp';
import services4  from './../../images/our-services/pic4.webp'; 

import pcimg  from './../../images/pc.png'; 
import bg2  from './../../images/background/bgmap.png'; 

import bg1 from './../../images/background/bg1.webp';

const iconBlog =[
	{icon: <i className="flaticon-pen" />,desc:'To assess the aspirations and motivations of Indian students towards entrepreneurship.',	   title: 'Student Entrepreneurial Intention',},
	{icon: <i className="flaticon-diamond" />, desc:'To analyse the evolving career choice intention of Indian students towards entrepreneurship.',title: 'Students Career Choice Intention',},
	{icon: <i className="flaticon-devices" />, desc:'Evaluating students perceptions of the entrepreneurship culture and support at their university.',title: 'University Entrepreneurship Climate',},
	
];
const wraperBlog = [
	{icon: <i className="ti-headphone-alt" />, title: 'Market forecast',},
	{icon: <i className="ti-desktop" />, title: 'Most Versatile',},
	{icon: <i className="ti-ruler-pencil" />, title: 'Most Versatile',},
];
const wraperBlog2 = [
	{icon: <i className="ti-desktop" />, title: 'Lifetime Updates',},
	{icon: <i className="ti-ruler-pencil" />, title: 'Fast Supports',},
	{icon: <i className="ti-palette" />, title: 'Interactive Elements',},
	{icon: <i className="ti-file" />, title: 'Flexible Layoute',},
];

const counterBlog1 = [
	
	{icon: <i className="icon flaticon-avatar text-primary"style={{ fontSize:"44px"}} />, num: '13896',    title: 'Students Surveyed',},
    {icon: <i className="icon flaticon-bar-chart text-primary" style={{ fontSize:"44px"}} />, num: '31', title: "States/UTs Covered",},
	{icon: <i className="icon flaticon-file text-primary" style={{ fontSize:"44px"}} />, num: '1',   title: 'Edition',},	
		
];
const dlabBox=[
	{icon: <i className="ti-ruler-pencil"  />, title:'Drive Innovation', desc:'Sponsor GUESSS India to expand your brands visibility across a diverse audience, making a tangible mark on student entrepreneurship', image: services1,  },
	{icon: <i className="ti-heart" />, title:'Shape Policy', desc:'Your sponsorship empowers research that informs policy-making, enhancing the student entrepreneurship ecosystem in India and driving positive',image: services2,  },
	{icon: <i className="ti-brush-alt" />, title:'Foster Relationship', desc:'Supporting IIT Mandis academic research enhances your brands prestige and opens doors to potential collaborations and partnerships.',image: services3,  },
	{icon: <i className="ti-mouse-alt" />, title:'Unveil Potential', desc:'By associating with GUESSS India and IIT Mandi, you unlock avenues for future synergies and innovative collaborations.',image: services4,  },
];

class Index2 extends Component {

    componentDidMount() {  
		var GalleryCategory = document.querySelectorAll('.box-hover');
		var fch = [].slice.call(GalleryCategory);
		
		for (var y = 0; y < fch.length; y++) {
            fch[y].addEventListener('mouseenter', function () { 
				galleryActive(this);
			});
        }
        
		function galleryActive(current) 
		{
            fch.forEach(el => el.classList.remove('active'));
			
			setTimeout(() => {
				 current.classList.add('active');
			},  100); 
		}
    }
    render() {
        return (
            <>
                <Header />

                <div className="page-content">
					{ /* <ReactRevSlider2 /> */}
					<div className="owl-slider-banner main-slider dz-style-1">
						<Index7Slider />
					</div>	
                    {/* <!-- About Us --> */}
                    {/* <div className="section-full content-inner bg-white">
                        <div className="container">
                            <div className="max-w800 m-auto  text-center">
                                <h6>GUESSS SURVEY 2023</h6> 
                               <h2 className="m-b50 "> <span style={{color:"#6CC000",fontWeight:"bold",fontStyle:"Italic"}}>GUESSS India 2023</span> is the maiden effort to survey Indian students' entrepreneurial spirit</h2>
                            </div>
                            </div>
                            </div>  */}
                           {/* <div className="row">
								{wraperBlog.map((item,index)=>(
									<div className="col-lg-4 col-md-6 col-sm-6 m-b30" key={index}>
										<div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
											<div className="icon-sm m-b20"> <Link to={"#"} className="icon-cell">{item.icon}</Link> </div>
											<div className="icon-content">
												<h5 className="dlab-tilte text-uppercase">{item.title}</h5>
												<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam.</p>
												<Link to={'about-1'} className="site-button">Site Button</Link>
											</div>
										</div>
									</div>
								))}
                            </div> */}
                       
                    {/* <!-- About Us End--> */}
                    {/* <!-- Services --> */}
                    
                    {/* <!-- Services END --> */}
                    {/* <!-- About Us --> */}
                    <div className="section-full content-inner bg-white">
                        <div className="container">
                        <div className="max-w800 m-auto  text-center">
                                {/* <h6>GUESSS SURVEY 2023</h6> */}
                                <h2 className="m-b50 "> <span style={{color:"#6CC000",fontWeight:"bold"}}>GUESSS India 2023</span> is the maiden effort to survey Indian students' entrepreneurial spirit</h2>
                            </div>
                            <div className="row m-b10 equal-wraper2">
                                <div className="col-xl-6 col-lg-6 col-md-12 equal-col" >
                                    <img src={about3} className="object-cover radius-sm" alt="" style={{height:'550px'}}/>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 dis-tbl equal-col">
                                    <div className="dis-tbl-cell">
                                         <h5 className="text-primary m-b5" style={{color:'black'}}>This report marks a significant milestone in our journey to understand and foster student entrepreneurship in India.</h5> 
                                         {/* <h2 className="font-weight-500 m-t0 m-b10"><span style={{lineHeight: '0.5em'}}> <span style={{color:"#6CC000",fontWeight:"bold",fontStyle:"Italic"}}></span></span></h2> 
                                        <h6 className="font-weight-400 m-b30">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</h6>  */}
                                        <br></br><div className="row">
											{counterBlog1.map((item,index)=>(
												<div className="col-lg-8 col-md-6 col-sm-6 m-b40">
													<div className="icon-bx-wraper  left" style={{ display: 'flex', alignItems: 'flex-start' }}>
														<div className="icon-sm text-primary"> 
															<Link to={"#"} className="icon-cell text-primary" >{item.icon}</Link>
														</div>
														<div className="counter-style-1" style={{marginTop:"20px"}}>
															
															<h6 className="font-weight-300"><span className="counter"><CountUp end={item.num} /></span></h6>
                                                            <h5 className="dlab-tilte">{item.title}</h5>
														</div>
													</div>
												</div>
                                               
											))} 
										</div>
                                        <Link to={"./coming-soon-1"} className="site-button radius-xl outline outline-2"><span className="p-lr10">Download Report</span></Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                         
               
                   
                 
                    <div className="section-full content-inner-1 overlay-primary about-service bg-img-fix" style={{ backgroundImage: `url(${bg1})`,
  height: window.innerWidth <= 1000 ? (window.innerWidth <= 900 ? '1000px' : '760px') : '600px' }} >
					<div className="container">
						<div className="section-head text-white text-center">
							<h2 className="box-title m-tb0 max-w800 m-auto">Why GUESSS India?<span className="bg-primary"></span></h2>
							<p style={{textAlign:"justify"}}>The primary goal of GUESSS India is to explore and understand the entrepreneurial intentions, attitudes, and behaviours of Indian students. This survey is conducted across the Indian higher educational institutions, encompassing a diverse range of students from various academic disciplines and educational levels.</p>
						</div>
					</div>
					<div className="container">
						<div className="row text-white">
							{iconBlog.map((item, index)=> (
								<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
									<div className="icon-bx-wraper bx-style-1 p-a30 center">
										{/* <div className="icon-lg text-white m-b20"> <Link to={"#"} className="icon-cell text-white">{item.icon}</Link> </div> */}
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">{item.title}</h5>
											<p>{item.desc}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					
				</div>	
                    {/* <!-- Team And Process END --> */}
                    {/* <!-- Vision And miSion--> */}
                    <div className="section-full m-t0 content-inner" style={{ backgroundImage: "url(" + bg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: 'cover'}}>
                            <div className="container">
                                <div className=" text-center">
                                    <h2 className="box-title" style={{margin:"0px"}}>Country Delegate's Message<span className="bg-primary"></span></h2>
                                    {/* <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                */}</div> 
                                <div className="section-content m-b30 ">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div>
                    <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                    <div style={{height:'600px'}}>
                                    <img src={about1} alt="" className="img-cover" />
                                        </div>
                                       
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>Vision</h2>
                                                        <h5 className="m-b30"style={{textAlign:"justify" , lineHeight:"32px"}}> To become a Premier Research Center for 
exploring and promoting student entrepreneurship 
in India, thereby contributing to the country's 
economic growth and innovation landscape.</h5>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>Mission</h2>
                                                        <h5 className="m-b30" style={{textAlign:"justify" , lineHeight:"32px"}}> To research the entrepreneurial spirit and 
career aspirations of Indian students, 
construct knowledge for policymakers, and 
support a new generation of 
change-makers as they carve out their 
future career paths.</h5>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                      <div style={{height:'600px'}}>
                                        <img src={about2} alt="" className="img-cover"/>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                   
                    <div className="section-full bg-white content-inner">
                        <div className="container">
                            <div className="max-w600 m-auto text-center m-b30">
                                {/* <h6 className="m-t0">Publish what you think</h6> */}
                                <h2 className="m-t0">Insights</h2>
                            </div>
                            <LatestBlogSlider />
                        </div>
                    </div>
                      {/* <!-- Testimonials --> */}
                      <div className="section-full bg-white p-tb15">
                      <h2 className="m-t0 text-center">Our Partners</h2>
                        <div className="container">
							<Index7ClientCarousel /> 
                        </div>
                    </div>
                {/* FAQS section */}
                <Faqs/>
                </div>
                <Footer />
            </>
        )
    }
}
export default Index2;