import React, { Component } from 'react';
import Slider from "react-slick";

// Images
import pic1 from './../../images/testimonials/pic1.jpg';
import pic2 from './../../images/portfolio/image_5_1.webp';
import pic3 from './../../images/portfolio/image_4_1.webp';
import pic4 from './../../images/puran.jpg';
class TestimonialCarousel extends Component {
    render() {
        var settings = {
            arrows: false,
            slidesToShow: 2,  // Display two testimonials
            infinite: true,
            dots: true,
            centerMode: true,  // Centers the slides
            centerPadding: '0',  // Removes padding from centered slides
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,  // On smaller screens, show only 1 slide
                        centerMode: true,
                        centerPadding: '30px',  // Padding for smaller screens
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,  // Mobile view, 1 testimonial
                        centerMode: true,
                        centerPadding: '20px',  // Padding for mobile screens
                    }
                }
            ]
        };

        return (
            
                <div className="content-block">
                      
    <div className="abuot-box row" style={{ display:"flex", flexDirection:"row" , flexWrap:"wrap", padding: '20px' }} >
      <div className="col-lg-4">
        <div className="about-img">
          <img src={pic4} data-tilt alt="" />
        </div>
        <h2 className="box-title m-tb10" style={{ lineHeight: '30px' }}>
          Dr. Puran Singh
          <span className="bg-primary"></span>
        </h2>
        <h4 className="text-gray-dark">Country Delegate, GUESSS India</h4>
      </div>
      <div className="col-lg-8">
        <p style={{ fontSize: '16px', lineHeight: '18px', textAlign: 'justify' , width: window.innerWidth <= 768 ? '100%' : '98%' , color:"black" , fontWeight:"530" }}>
        I am delighted to present the inaugural edition of the GUESSS India Survey Report. This
comprehensive survey captures the entrepreneurial spirit and aspirations of over 13,800
students from across the country, offering a unique and insightful glimpse into the future of
India's startup ecosystem. <br/><br/>Our journey in compiling this report has been both enlightening and inspiring. The data
collected reflects the dynamic and evolving landscape of student entrepreneurship in India,
highlighting the ambitions, challenges, and opportunities faced by young entrepreneurs. We
hope that the insights provided here will serve as a valuable resource for educators,
policymakers, universities, industry leaders, and aspiring entrepreneurs.<br/> <br/>
One of the key goals of this report is to facilitate a deeper understanding of the
entrepreneurial intentions and activities of Indian students. The study of students’
entrepreneurial orientation will help create a more supportive environment that nurtures the
next generation of innovators and business leaders. The findings presented in this report
underscore the importance of entrepreneurship education and access to resources in
shaping entrepreneurial outcomes. <br/><br/>
I believe that collaboration and shared knowledge are essential for advancing the field of
entrepreneurship. Therefore, I warmly invite researchers to collaborate with us in utilizing this
extensive dataset for further research. Together, we can explore new dimensions of student
entrepreneurship, generate valuable insights, and contribute to the development of effective
strategies that promote entrepreneurial success. <br/><br/>
I extend our heartfelt gratitude to all the students who participated in the survey, the campus
ambassadors, educational institutions, and partners who supported the 2023 survey round. <br/><br/>
Thank you for your interest in the GUESSS India Survey. I look forward to continuing this
important dialogue on student entrepreneurship in India.
        </p>
      </div>
    </div>
  </div>

          
        );
    }
}

export default TestimonialCarousel;
