import React, { Component } from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import { db } from '../../firebase'; // Ensure this path is correct
import { collection, addDoc } from "firebase/firestore"; // Import Firestore functions
import bgimg from './../../images/background/bg9_1.webp';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            message: '',
            successMessage: '',
            errorMessage: ''
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const { name, phone, email, message } = this.state;

        try {
            // Create a new entry in Firestore
            await addDoc(collection(db, 'contacts'), {
                name,
                phone,
                email,
                message,
                timestamp: new Date().toISOString()
            });

            // Set success message and clear the form
            this.setState({
                successMessage: 'Your message has been sent successfully!',
                errorMessage: '',
                name: '',
                phone: '',
                email: '',
                message: ''
            });
        } catch (error) {
            // Set error message
            this.setState({ 
                errorMessage: 'There was an error sending your message. Please try again later.',
                successMessage: ''
            });
        }
    };

    render() {
        return (
            <>
                <Header />
                <div className="full-section">
                    <div className="dlab-bnr-inr overlay-primary-dark contact-page" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-xs-4">
                                    <div className="row text-white">
                                        <div className="col-lg-12 col-md-6 m-b30">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                       Address
													</h5>
                                                    <p>School of Management, North Campus, IIT Mandi, Kamand, Distt. Mandi H.P. 175005</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                                        E-mail
													</h5>
                                                    <p className="m-b0">support@guesssindia.in</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-xs-8">
                                    <form className="inquiry-form dzForm" onSubmit={this.handleSubmit}>
                                        <h3 className="box-title m-t0 m-b10">Send a message<span className="bg-primary"></span></h3>
                                        <p>We’d love to hear from you! Whether you have a question, suggestion, or just want to say hello, feel free to get in touch with us.</p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="name" type="text" required className="form-control" placeholder="First Name" value={this.state.name} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="phone" type="text" required className="form-control" placeholder="Phone" value={this.state.phone} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="email" type="email" className="form-control" required placeholder="Your Email Id" value={this.state.email} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="message" rows="4" className="form-control" required placeholder="Write your message here" value={this.state.message} onChange={this.handleChange}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <button name="submit" type="submit" value="Submit" className="site-button button-lg"> <span>Send Message</span> </button>
                                            </div>
                                        </div>
                                        {this.state.successMessage && <div className="alert alert-success">{this.state.successMessage}</div>}
                                        {this.state.errorMessage && <div className="alert alert-danger">{this.state.errorMessage}</div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Contact;
