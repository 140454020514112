import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
import FormStyle from './../../Element/FormStyle';
import {PortfolioItem} from './../ShortCode/ProtfolioStyle3';

//Images
import  about9 from './../../../images/about/about.webp';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/v1.png';
import services2 from './../../../images/our-services/v2.png';
import bg1 from './../../../images/background/bg1.jpg';

import bnr from './../../../images/banner/bnr2.jpg';
import ProjectDetails from '../ProjectDetails';

class Aboutus1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='About Us' activeMenu='About Us' />
					</div>	
                    
                    <div className="content-block m-t0">
                        <div className="section-full content-inner-2">
                            <div className="container">
                           
										<ProjectDetails /> 
                                     
                                        </div>
                                        { /* Counetr section */ }
							<SectionCounter />
                                     {/* <!-- Vision And miSion--> */}
                                     <div className="section-head text-black text-center m-t100">
                                    {/* <h4 className="text-gray-dark m-b10">GUESSS Project</h4> */}
                                    <h2 className="box-title m-tb0">GUESSS Global<span className="bg-primary"></span></h2>
                                    
                                    {/* <p>GUESSS stands for Global University Entrepreneurial Spirit Students Survey. It is one of the largest entrepreneurship research projects in the world. The main research focus is students entrepreneurial intentions and activities, including the topic of family firm succession.
                                    GUESSS data have been used for several publications in leading international academic journals such as Journal of Business Venturing (JBV) or Entrepreneurship Theory & Practice (ETP)</p>
                               */}  </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img m-b100">
                                        <img src={about9} data-tilt alt="" style={{height:"800px" , width:"370px" , zIndex:"1"}} />
                                    </div>
                                    <div className="col-lg-7 col-md-8" style={{zIndex:"99"}}>
                                        <div className="abuot-box left row m-lr0 ">
                                            <div className="col-lg-12">
                                                <h2 className="box-title m-tb0">Background<span className="bg-primary"></span></h2>
                                                <h4 className="text-gray-dark">of GUESSS Global</h4>
                                             
                                            
                                                <p>GUESSS, or the Global University Entrepreneurial Spirit Students' Survey, is a comprehensive research initiative established in 2003. It aims to analyze the entrepreneurial intentions and activities of students globally. Coordinated by teams from the University of Bern and the University of St. Gallen in Switzerland, GUESSS has expanded significantly over the years, involving 57 national teams and gathering over 226,718 responses worldwide in its latest iteration.</p>
                                                <p>The project employs a web-based anonymous survey to explore various dimensions of student entrepreneurship, including career choices, entrepreneurial intentions, and the socio-demographic profiles of students. The theoretical foundation of GUESSS is based on the Theory of Planned Behavior, which connects attitudes, subjective norms, and perceived behavioral control to entrepreneurial intentions. By incorporating factors such as university environment and family influence, GUESSS provides valuable insights into the dynamics of student entrepreneurship across different cultural contexts.<br></br> <br></br>For Academic Research Publications visit: <a href='https://www.guesssurvey.org/publications/publications/academic-journals.html' style={{color:'#6cc000'}}> GUESSS Academic Publications</a></p>
                                                </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                    <div className="section-full m-t20 ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                    <div style={{height:'600px'}}>
                                    <img src={services1} alt="" className="img-cover" />
                                        </div>
                                       
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>Vision</h2>
                                                        <h5 className="m-b30" style={{textAlign:"justify", lineHeight:"32px"}}>To become a Premier Research Center for 
exploring and promoting student entrepreneurship 
in India, thereby contributing to the country's 
economic growth and innovation landscape.</h5>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>Mission</h2>
                                                        <h5 className="m-b30" style={{textAlign:"justify", lineHeight:"32px"}}>To research the entrepreneurial spirit and 
career aspirations of Indian students, 
construct knowledge for policymakers, and 
support a new generation of 
change-makers as they carve out their 
future career paths.</h5>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                      <div style={{height:'600px'}}>
                                        <img src={services2} alt="" className="img-cover"/>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                             
                        </div>
						
						{ /* Counetr section End*/ }
							<VisionBlog />
							
                          {/* <!-- Portfolio --> */}
						<div className="section-full bg-white content-inner-1 portfolio">
							<div className="container-fluid">
								<PortfolioItem />
							</div>
						</div>			
                    {/* <!-- Portfolio End --> */}
                        {/* <!-- Testimonials END --> */}
                        {/* <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div> */}
                    </div>
                
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			{/* <div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">Mission And Vision<span className="bg-primary"></span></h2> */}
						{/* <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p> */}
					{/* </div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mision<span className="bg-primary"></span></h3>
								<p className="font-16">To study the entrepreneurial spirit and career aspirations of Indian students, creating knowledge that empowers incubators, the broader ecosystem, policymakers and supporting a new generation of change-makers as they carve out their future career paths.</p>
							</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Vision<span className="bg-primary"></span></h3>
								<p className="font-16">
                                To become a Premier Research Center for exploring and promoting student entrepreneurship in India, thereby contributing to the country's economic growth and innovation landscape.</p>	</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>
				</div>
			</div> */}
            
		</>
	)
}	

export {VisionBlog};
export default Aboutus1;