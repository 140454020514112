import React, { Component } from 'react';
import Slider from "react-slick";

import logo1 from './../../images/client-logo/logo1.png';
import logo2 from './../../images/client-logo/logo2.png';
import logo3 from './../../images/client-logo/logo3.png';
import logo4 from './../../images/client-logo/logo4.png';
import logo5 from './../../images/client-logo/logo5.png';

const imgblog = [
    { image: logo1, width: "150px" },
    { image: logo4, width: "180px" },
    { image: logo3, width: "90px" },
    { image: logo5, width: "160px" },
    { image: logo2, width: "120px" },
];

class Index7ClientCarousel extends Component {
    render() {
        var settings = {
            arrows: false,
            slidesToShow: 5,
            infinite: true,
            autoplay: true,
            speed: 2000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 591,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 360,
                    settings: {
                        slidesToShow: 2,
                    }
                },
            ]
        };

        return (
            <Slider className="" {...settings}>
                {imgblog.map((item, index) => (
                    <div className="item" key={index}>
                        <div 
                            className="client-logo text-center" 
                            style={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                height: '150px', // Set a fixed height for alignment
                                overflow: 'hidden' // Prevent overflow of images
                            }}
                        >
                            <img 
                                src={item.image} 
                                style={{ 
                                    width: item.width, 
                                    height: '100%', // Make height 100% to fit the container
                                    objectFit: 'contain' 
                                }} 
                                alt={`Client logo ${index + 1}`} 
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        )
    }
}

export default Index7ClientCarousel;
